import React, { useEffect, useState } from "react";
import InputField from "../../../components/fields/InputField";
import "../../../css/spinner.css";
import api from "views/api";

function WithdrawForm() {
  const [currentTPMS, setCurrentTPMS] = useState({});
  const [loading, setLoading] = useState(true);

  const [depositedAmount, setDepositedAmount] = useState(0);
  const [profitAndLoss, setProfitAndLoss] = useState(0);
  const [tradingBalance, setTradingBalance] = useState(0);
  const [withdrawableAmount, setWithdrawableAmount] = useState(0);
  const [withdrawAmount, setWithdrawAmount] = useState(0);

  useEffect(() => {
    const fetchDataForTPMS = async (uuid) => {
      try {
        console.log("Started fetching");
        const response = await api.get(`/tpmsbyuuid/${uuid}`);
        const tpms = response.data;
        setCurrentTPMS(tpms);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const userId = user.uuid;
    fetchDataForTPMS(userId);
    console.log(currentTPMS);
  }, []);

  useEffect(() => {
    if (currentTPMS?.tpms?.id) {
      setDepositedAmount(currentTPMS?.tpms?.depositedAmount);
      setProfitAndLoss(currentTPMS?.tpms?.pnlamount);
      setWithdrawableAmount(
        currentTPMS?.tpms?.pnlamount > 0 ? currentTPMS?.tpms?.pnlamount : 0
      );
    }
  }, [currentTPMS]);

  const handleWithdrawAmountChange = (e) => {
    setWithdrawAmount(e.target.value);
  };

  const handleWithdraw = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (parseFloat(withdrawAmount) > withdrawableAmount) {
      alert("Insufficient balance");
      setLoading(false);
      return;
    }

    try {
      const response = await api.post("/withdrawalrequests", {
        tpmsId: currentTPMS.tpms.id,
        amount: parseFloat(withdrawAmount),
      });

      alert(response.data.msg);
      setWithdrawAmount("");
    } catch (error) {
      console.error("Error processing withdrawal request:", error);
      alert("Error processing withdrawal request.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleWithdraw}>
      <div className="mt-5 h-full w-full flex-col items-center justify-center rounded-[10px] border-[1px] border-gray-200 bg-white bg-clip-border px-6 pb-6 pt-4 shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none sm:overflow-x-auto">
        <div className="mb-3 text-center">
          <div className="mt-2 rounded  p-3 text-2xl font-semibold text-green-700 dark:bg-green-900 dark:text-green-300">
            <label className="block text-lg font-bold text-navy-700 dark:text-white">
              Withdrawable Balance
            </label>
            ₹{withdrawableAmount.toFixed(2)}
          </div>
        </div>
        <div className="mb-3">
          <InputField
            id="withdraw-amount"
            name="withdrawAmount"
            label="Amount to Withdraw"
            extra=""
            placeholder="Enter amount to withdraw"
            variant="auth"
            type="number"
            value={withdrawAmount}
            onChange={handleWithdrawAmountChange}
          />
        </div>
      </div>
      <button
        type="submit"
        className="m-5 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
        disabled={loading}
      >
        {loading ? <div className="spinner"></div> : "Proceed"}
      </button>
    </form>
  );
}

export default WithdrawForm;
