import api from "views/api";


export const fetchLeadData = async () => {
  const user = JSON.parse(localStorage.getItem("user") || "");
  const vendorId = user.uuid; 
  const response = await api.get(`/leadsByVendor/${vendorId}`);

  const data = await response.data;

  return data.map((lead, index) => ({
      action: index + 1,
      name: lead.name,
      status: lead.lead_status,
      date: lead.createdAt,
      call: lead.mobile,
      progress: 50,
      vendorName: lead.createdByName,
      email: lead.email,
      address: lead.address,
      productCategory: lead.product_category,
      productSelection: lead.product_selection,
    })
);

};