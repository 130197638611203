import api from "views/api";



export const fetchVendorData = async () => {
  const response = await api.get("/vendors");

  const data = await response.data;

  return data.map((vendor, index) => ({
    id: vendor.uuid,
    code: vendor.vendorCode,
    isDummy: vendor.isDummy,
    name: vendor.name,
    call: vendor.mobile,
    email: vendor.email,
    address: vendor.address,
    action: index + 1,
  }));
  
};
