import React, { useEffect, useState } from "react";
import tableDataLead, { fetchLeadData } from "./variables/tableDataLeads.jsx";
import LeadsTable from "./components/LeadsTable.jsx";
import VendorsTable from "./components/VendorsTable.jsx";
import { fetchVendorData } from "./variables/tableDataVendors.jsx";
import DealsTable from "./components/DealsTable.jsx";

const Tables = () => {
  const [tableDataVendors, setTableDataVendors] = useState([]);
  const [tableDataLeads, setTableDataLeads] = useState([]);
  const [tableDataDeals, setTableDataDeals] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("Fetching data...");
        const data = await fetchVendorData();
        setTableDataVendors(data);
      } catch (error) {
        console.error("Error fetching vendor data:", error);
      }
    };
  
    fetchData();
  }, []); 
  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("Fetching data...");
        const data = await fetchLeadData();
        setTableDataLeads(data);
      } catch (error) {
        console.error("Error fetching vendor data:", error);
      }
    };
  
    fetchData();
  }, []);
  
  useEffect(() => {
    const filteredDeals = tableDataLeads.filter((lead) => lead.status === "Trade Done" || lead.status === "Account Created" || lead.status === "Fund Added");
    setTableDataDeals(filteredDeals);
  }, [tableDataLeads]);



  return (
    <div className="mt-5 ">
      <div className="mt-5 ">
        <VendorsTable tableData={tableDataVendors} />
      </div>
      <div className="mt-5 ">
        <LeadsTable
          setTableData={setTableDataLeads}
          tableData={tableDataLeads}
        />
      </div>

      <div className="mt-5 ">
        <DealsTable
          tableData={tableDataDeals}
          setTableData={setTableDataLeads}
        />
      </div>
    </div>
  );
};

export default Tables;
