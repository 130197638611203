import React, { useEffect, useState } from "react";
import TransactionTable from "./components/TransactionTable";
import WithdrawalRequestTable from "./components/WithdrawalRequestTable";
import { fetchTransactionData } from "./variables/tableDataTransaction.jsx";
import { fetchWithdrawalRequestData } from "./variables/tableDatawithdrawalRequest";

const Tables = ({ currentTPMS, isAdmin }) => {
  const [tableDataTransactions, setTableDataTransactions] = useState([]);
  const [tableDataWithdrawalRequest, setTableDataWithdrawalRequest] = useState(
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (currentTPMS?.tpms?.id) {
          const data = await fetchWithdrawalRequestData(currentTPMS.tpms.id);
          setTableDataWithdrawalRequest(data);
          console.log(data);
        }
      } catch (error) {
        console.error("Error fetching transaction data:", error);
      }
    };

    fetchData();
  }, [currentTPMS]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (currentTPMS?.tpms?.id) {
          const data = await fetchTransactionData(currentTPMS.tpms.id);
          setTableDataTransactions(data);
          console.log(data);
        }
      } catch (error) {
        console.error("Error fetching transaction data:", error);
      }
    };

    fetchData();
  }, [currentTPMS]);

  return (
    <div className="mt-5">
      <div className="mt-5">
        <WithdrawalRequestTable
          isAdmin={isAdmin}
          tableData={tableDataWithdrawalRequest}
        />
        <TransactionTable tableData={tableDataTransactions} />
      </div>
    </div>
  );
};

export default Tables;
