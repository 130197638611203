import React, { useState } from "react";
import InputField from "../../../components/fields/InputField";

import CustomDropdown from "../../../components/dropdowncompov";
import api from "views/api";
import "../../../css/spinner.css";
import Checkbox from "components/checkbox";

function Form() {
  const productCategoryOptions = ["Insurance", "Free Demat Account", "TPP" ,"BANK"];

  const insuranceOptions = [
    "Health Insurance",
    "General Insurance",
    "Life Insurance",
  ];
  const dematAccountOptions = [
    "Choice",
    "Upstox",
    "Angel Broking",
    "Paytm Money",
    "Motilal Oswal",
  ];
  const tppOptions = ["TPMS", "Mutual Fund", "PMS"];

  const BankOptions = ["Saving Account",  "Credit Card"];

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    productCategory: productCategoryOptions[0],
    productSelection: [],
    address: "",
  });

  const [productSelectionOptions, setProductSelectionOptions] =
    useState(insuranceOptions);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDropdownChange = (name, value) => {
    if (name === "productCategory") {
      let newOptions;
      switch (value) {
        case "Insurance":
          newOptions = insuranceOptions;
          break;
        case "Free Demat Account":
          newOptions = dematAccountOptions;
          break;
        case "TPP":
          newOptions = tppOptions;
          break;
        case "BANK":
          newOptions = BankOptions;
          break;
        default:
          newOptions = [];
      }
      setProductSelectionOptions(newOptions);
      setFormData({
        ...formData,
        productCategory: value,
        productSelection: [],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleCheckboxChange = (option) => {
    setFormData((prevState) => {
      const newSelection = prevState.productSelection.includes(option)
        ? prevState.productSelection.filter((item) => item !== option)
        : [...prevState.productSelection, option];
      return { ...prevState, productSelection: newSelection };
    });
  };

  function generatePass() {
    let pass = "";
    let str =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ" +
      "abcdefghijklmnopqrstuvwxyz0123456789@#$" +
      "1234567890";

    for (let i = 1; i <= 8; i++) {
      let char = Math.floor(Math.random() * str.length + 1);
      pass += str.charAt(char);
    }

    return pass;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const {
      firstName,
      lastName,
      email,
      mobile,
      productCategory,
      productSelection,
      address,
    } = formData;
    const fullName = `${firstName} ${lastName}`;
    const password = generatePass();
    const confPassword = password;
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const vendorId = user.uuid;
    const createdByName = user.name;
    const leadStatus = "In Progress";
    const vendorData = {
      name: fullName,
      password,
      confPassword,
      email,
      mobile,
      product_category: productCategory,
      product_selection: productSelection,
      lead_status: leadStatus,
      address,
      vendorId,
      createdByName,
    };
    console.log(vendorData);
    try {
      const response = await api.post("/leads", vendorData);
      console.log(response);

      if (response.status === 201) {
        alert("Lead added successfully!");
        // Optionally, clear the form or redirect to another page
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          mobile: "",
          productCategory: productCategoryOptions[0],
          productSelection: [],
          address: "",
        });
        setProductSelectionOptions(insuranceOptions);
      } else {
        alert(`Error: ${response.data.message}`);
      }
    } catch (error) {
      alert(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="mt-5 h-full w-full flex-col items-center justify-center rounded-[10px] border-[1px] border-gray-200 bg-white bg-clip-border px-6 pb-6 pt-4 shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none sm:overflow-x-auto">
          <div className="mb-3">
            <InputField
              required={true}
              id="first-name"
              name="firstName"
              label="First Name"
              extra=""
              placeholder="Enter your first name"
              variant="auth"
              type="text"
              value={formData.firstName}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <InputField
              required={true}
              id="last-name"
              name="lastName"
              label="Last Name"
              extra=""
              placeholder="Enter your last name"
              variant="auth"
              type="text"
              value={formData.lastName}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <InputField
              required={true}
              id="email"
              name="email"
              label="Email"
              extra=""
              placeholder="Enter your email"
              variant="auth"
              type="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <InputField
              required={true}
              id="mobile"
              name="mobile"
              label="Mobile"
              minLength={10}
              extra=""
              placeholder="Enter your mobile number"
              variant="auth"
              type="tel"
              value={formData.mobile}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <CustomDropdown
              options={productCategoryOptions}
              label="Select Product Category"
              selectedOption={formData.productCategory}
              onChange={(value) =>
                handleDropdownChange("productCategory", value)
              }
            />
          </div>
          <div className="mb-3">
            <label className="form-label text-sm font-bold text-navy-700 dark:text-white">
              Select Product(s)
            </label>
            <div className="flex flex-wrap">
              {productSelectionOptions.map((option) => (
                <div key={option} className="mb-2 mr-4">
                  <Checkbox
                    color="blue"
                    extra="mr-2"
                    checked={formData.productSelection.includes(option)}
                    onChange={() => handleCheckboxChange(option)}
                  />
                  <label className="text-sm font-bold text-navy-700 dark:text-white">
                    {option}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="mb-3">
            <label
              htmlFor="address"
              className="form-label text-sm font-bold text-navy-700 dark:text-white"
            >
              Address
            </label>
            <textarea
              id="address"
              name="address"
              placeholder="Enter your address"
              className="form-control mt-2 flex h-32 w-full rounded-xl border border-gray-200 bg-white/0 p-3 text-sm outline-none dark:!border-white/10 dark:text-white"
              value={formData.address}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <button
          type="submit"
          className="m-5 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
          disabled={loading}
        >
          {loading ? <div className="spinner"></div> : "Submit"}
        </button>
      </form>
    </>
  );
}

export default Form;
