import React, { useState } from "react";
import InputField from "components/fields/InputField";
import { useNavigate } from "react-router-dom";
import api from "../api";
import Checkbox from "components/checkbox";

function ChangePassword() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showPassword, setShowPassword] = useState(false); 

  const handleChangePassword = async (e) => {
    e.preventDefault();
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const userId = user.uuid;

    if (newPassword !== confirmPassword) {
      setError("New passwords do not match");
      setSuccess("");
      return;
    }

    try {
      const response = await api.post("/changePassword", {
        uuid: userId,
        newPassword,
      });
      setError("");
      setSuccess("Password changed successfully!");
    } catch (error) {
      console.error("Error changing password:", error);
      setError("An error occurred while changing the password");
      setSuccess("");
    }
  };

   const handleCheckboxChange = () => {
     setShowPassword(!showPassword);
   };

  return (
    <div className="mt-5 h-full w-full flex-col items-center justify-center rounded-[10px] border-[1px] border-gray-200 bg-white bg-clip-border px-6 pb-6 pt-4 shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none sm:overflow-x-auto">
      <form onSubmit={handleChangePassword}>
        <InputField
          required={true}
          id="new-password"
          name="newPassword"
          label="New Password"
          type={showPassword ? "text" : "password"}
          placeholder="Enter new password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          variant="auth"
          extra="mb-4"
          minLength={8}
          state={error ? "error" : success ? "success" : ""}
        />
        <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox
              color="blue"
              extra="mr-2"
              checked={showPassword}
              onChange={() => handleCheckboxChange()}
            />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Show Password
            </p>
          </div>
        </div>
        <InputField
          required={true}
          id="confirm-password"
          name="confirmPassword"
          label="Confirm New Password"
          type={showPassword ? "text" : "password"}
          placeholder="Confirm new password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          variant="auth"
          extra="mb-4"
          minLength={8}
          state={error ? "error" : success ? "success" : ""}
        />
        {error && <div className="mb-4 text-red-500">{error}</div>}
        {success && <div className="mb-4 text-green-500">{success}</div>}
        <button
          type="submit"
          className="mt-2 h-12 w-full rounded-xl bg-blue-500 text-white"
        >
          Change Password
        </button>
      </form>
    </div>
  );
}

export default ChangePassword;
