import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/modal";
import { useDisclosure } from "@chakra-ui/hooks";
import { useEffect, useState } from "react";
import DropDownCompo from "./DropdownCompo";
import api from "views/api";
import { useNavigate } from "react-router-dom";

const ModalCompo = ({ onOpen, isOpen, onClose, data, id }) => {
  const [currentUserData, setCurrentUserData] = useState({});
  const [selectedState, setSelectedState] = useState(null);
  const [dummy, setDummy] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const user = data.find((user) => user.action === id);
    if (user) {
      setCurrentUserData(user);
      if (user.isDummy) {
        setDummy(true);
      }
    }
  }, [id, data]);

  const handleStateSelect = (state) => {
    setSelectedState(state);
    console.log("Selected Stat:", state);
  };

  const deleteVendor = async () => {
    const uuid = currentUserData?.id;
    try {
      const response = await api.delete(`/vendor/${uuid}`);
      if (response.status == 200) {
        alert("Vendor Deleted SuccessFully!");
        navigate(0);
        onClose();
      } else {
        alert("Vendor could not be deleted");
      }
    } catch (error) {
      alert("Failed to delete vendor");
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const uuid = id;
    const lead_status = selectedState;
    const statusData = {
      uuid,
      lead_status,
    };

    try {
      const response = await api.post("/changeStatus", statusData);
      if (response.status == 200) {
        alert("Status Changed SuccessFully!");
        onClose();
      } else {
        alert("Status could not change");
      }
    } catch (error) {
      alert("Failed to add vendor");
      console.error(error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="!z-[1010]">
      <ModalOverlay className="bg-[#000] !opacity-30" />
      <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh] ">
        <ModalBody>
          <div className="!z-[1004] mt-[150px] flex max-w-[450px] flex-col rounded bg-white px-[30px] pb-[40px] pt-[35px] dark:bg-navy-400">
            <h1 className="mb-[20px] text-2xl font-bold dark:text-white">
              {currentUserData?.name}
            </h1>

            {dummy && (
              <div className="mb-10 flex w-full">
                <button
                  onClick={deleteVendor}
                  className="rounded-xl bg-red-600 px-5 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-blueSecondary/50"
                >
                  Delete
                </button>
              </div>
            )}
            <div className="flex gap-2">
              <button
                onClick={onClose}
                className="linear rounded-xl border-2 border-red-500 px-5 py-3 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
              >
                Close
              </button>
              <button
                onClick={onClose}
                className="rounded-xl bg-gradient-to-br from-brandLinear to-blueSecondary px-5 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-blueSecondary/50"
              >
                Save
              </button>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalCompo;
