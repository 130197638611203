import React, { useEffect, useState } from "react";
import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import Progress from "components/progress";
import {
  MdCancel,
  MdCheckCircle,
  MdOutlineError,
  MdPending,
} from "react-icons/md";
import { useDisclosure } from "@chakra-ui/hooks";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import ModalCompo from "./ModalCompo.jsx";
import SearchBar from "./SearchBar.jsx";
import SearchFilter from "./SearchFilter.jsx";

const columnHelper = createColumnHelper();

function ComplexTable({ tableData }) {
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [selectText, setSelectText] = useState("");
  const [selectProduct, setSelectProduct] = useState("");
  const [productOptions, setProductOptions] = useState([]);

  const handleSearchFilterChange = (value) => {
    setSelectText(value);
    setSelectProduct(""); // Reset product selection when category changes
  };

  const handleProductFilterChange = (value) => {
    setSelectProduct(value);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const combineFilters = (data, searchText, selectText, selectProduct) => {
    return data.filter((item) => {
      const searchMatch = searchText
        ? Object.values(item).some(
            (val) =>
              val &&
              val.toString().toLowerCase().includes(searchText.toLowerCase())
          )
        : true;

      const categoryMatch = selectText
        ? item["productCategory"] === selectText
        : true;

      const productMatch = selectProduct
        ? item["productSelection"] === selectProduct
        : true;

      return searchMatch && categoryMatch && productMatch;
    });
  };

  const productCategoryOptions = [
    { value: "Insurance", label: "Insurance" },
    { value: "Free Demat Account", label: "Free Demat Account" },
    { value: "TPP", label: "TPP" },
  ];

  const insuranceOptions = [
    { value: "Health Insurance", label: "Health Insurance" },
    { value: "General Insurance", label: "General Insurance" },
    { value: "Life Insurance", label: "Life Insurance" },
  ];

  const dematAccountOptions = [
    { value: "Choice", label: "Choice" },
    { value: "Upstox", label: "Upstox" },
    { value: "Angel Broking", label: "Angel Broking" },
    { value: "Paytm Money", label: "Paytm Money" },
    { value: "Motilal Oswal", label: "Motilal Oswal" },
  ];

  const tppOptions = [
    { value: "TPMS", label: "TPMS" },
    { value: "Mutual Fund", label: "Mutual Fund" },
    { value: "PMS", label: "PMS" },
  ];

  useEffect(() => {
    let options = [];
    if (selectText === "Insurance") options = insuranceOptions;
    if (selectText === "Free Demat Account") options = dematAccountOptions;
    if (selectText === "TPP") options = tppOptions;
    setProductOptions(options);
  }, [selectText]);

  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">NAME</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("productCategory", {
      id: "productCategory",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          PRODUCT CATEGORY
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("productSelection", {
      id: "productSelection",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          PRODUCT SELECTION
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("status", {
      id: "status",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          STATUS
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          {info.getValue() === "Trade Done" ? (
            <MdCheckCircle className="me-1 text-green-500 dark:text-green-300" />
          ) : info.getValue() === "In Progress" ? (
            <MdPending className="me-1 text-red-500 dark:text-red-300" />
          ) : info.getValue() === "Error" ? (
            <MdOutlineError className="me-1 text-amber-500 dark:text-amber-300" />
          ) : null}
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {info.getValue()}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("date", {
      id: "date",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">DATE</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("call", {
      id: "call",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">CALL</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">EMAIL</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("address", {
      id: "address",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          ADDRESS
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("progress", {
      id: "progress",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          PROGRESS
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <Progress width="w-[108px]" value={info.getValue()} />
        </div>
      ),
    }),
  ];

  const [data, setData] = useState(() => [...tableData]);

  useEffect(() => {
    const filteredData = combineFilters(
      tableData,
      searchText,
      selectText,
      selectProduct
    );
    setData(filteredData);
    paginate(1);
  }, [searchText, selectText, selectProduct, tableData]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = table
    .getRowModel()
    .rows.slice(indexOfFirstRow, indexOfLastRow);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <ModalCompo
        onOpen={onOpen}
        onClose={onClose}
        isOpen={isOpen}
        data={data}
      />
      <Card extra="w-full h-full px-6 pb-6 overflow-x-auto">
        <div className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Leads Table
          </div>
          <CardMenu />
        </div>

        <div className="-mx-4 flex flex-wrap">
          <div className="mb-4 w-full px-4 md:w-1/2">
            <SearchBar text={searchText} setSearchText={setSearchText} />
          </div>
          <div className="mb-4 w-full px-4 md:w-1/2">
            <SearchFilter
              options={productCategoryOptions}
              onFilterChange={handleSearchFilterChange}
            />
          </div>
          <div className="mb-4 w-full px-4 md:w-1/2">
            <SearchFilter
              options={productOptions}
              onFilterChange={handleProductFilterChange}
            />
          </div>
        </div>

        <div className="mt-8 overflow-x-scroll">
          <table className="w-full">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className="!border-px !border-gray-400"
                >
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                    >
                      <div className="items-center justify-between text-xs text-gray-200">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: " ↑",
                          desc: " ↓",
                        }[header.column.getIsSorted() || null] || null}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {currentRows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="min-w-[150px] border-white/0 py-3  pr-4"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-4 flex justify-end">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="mr-2 rounded bg-gray-200 px-3 py-1 text-gray-700"
          >
            Previous
          </button>
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={indexOfLastRow >= table.getRowModel().rows.length}
            className="rounded bg-gray-200 px-3 py-1 text-gray-700"
          >
            Next
          </button>
        </div>
      </Card>
    </div>
  );
}

export default ComplexTable;
