import avatar from "assets/img/avatars/avatar8.png";
import banner from "assets/img/profile/banner.png";
import Card from "components/card";
import { useEffect, useState } from "react";
import api from "views/api";

const Banner = () => {
  const [name, setName] = useState("Name");

  const [leadCount, setLeadCount] = useState(0);
  const [vendorCount, setVendorCount] = useState(0);
  const [dealCount, setDealCount] = useState(0);
  const [representativeCount, setRepresentativeCount] = useState(0);

  useEffect(() => {
    const fetchUsersNumber = async () => {
      const response = await api.get("/roleCounts");

      const data = await response.data;

      console.log(response);

      setLeadCount(response.data.data.lead);
      setVendorCount(response.data.data.vendor);
      setDealCount(response.data.data.deal);
      setRepresentativeCount(response.data.data.representative);
    };

    fetchUsersNumber();
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const representativeId = user.uuid;
    const representativeName = user.name;
    setName(representativeName);
  });

  return (
    <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
      {/* Background and profile */}
      <div
        className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700">
          <img className="h-full w-full rounded-full" src={avatar} alt="" />
        </div>
      </div>

      {/* Name and position */}
      <div className="mt-16 flex flex-col items-center">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          {name}
        </h4>
        <p className="text-base font-normal text-gray-600">Manager</p>
      </div>

      {/* Post followers */}
      <div className="mb-3 mt-6 flex gap-4 md:!gap-14">
        <div className="flex flex-col items-center justify-center">
          <p className="text-2xl font-bold text-navy-700 dark:text-white">{vendorCount}</p>
          <p className="text-sm font-normal text-gray-600">Vendors</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <p className="text-2xl font-bold text-navy-700 dark:text-white">{leadCount}</p>
          <p className="text-sm font-normal text-gray-600">Leads </p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <p className="text-2xl font-bold text-navy-700 dark:text-white">{dealCount}</p>
          <p className="text-sm font-normal text-gray-600">Deals</p>
        </div>
      </div>
    </Card>
  );
};

export default Banner;
