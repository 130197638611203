import React, { useEffect, useState } from "react";
import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import Progress from "components/progress";
import { MdCancel, MdCheckCircle, MdOutlineError, MdPending } from "react-icons/md";
import { useDisclosure } from "@chakra-ui/hooks";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import ModalCompo from "./ModalCompo.jsx";
import SearchBar from "./SearchBar.jsx";
import SearchFilter from "./SearchFilter.jsx";


const columnHelper = createColumnHelper();

function ComplexTable({ tableData, setTableData }) {
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10); // Number of rows per page
  const [currentUserAction, setCurrentUserAction] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [selectText, setSelectText] = useState("");
  const [selectProduct, setSelectProduct] = useState("");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSearchFilterChange = (value) => {
    setSelectText(value);
  };

  const handleProductFilterChange = (value) => {
    setSelectProduct(value);
  };

  const combineFilters = (data, searchText, selectText, selectProduct) => {
    return data.filter((item) => {
      // Filter by search text (if provided)
      const searchMatch = searchText
        ? Object.values(item).some(
            (val) =>
              val &&
              val.toString().toLowerCase().includes(searchText.toLowerCase())
          )
        : true; // Allow all items if no search text

      // Filter by selected option (if provided)
      const filterMatch = selectText
        ? item["productCategory"] === selectText // Match the exact category
        : true; // Allow all items if no filter selected

      // Filter by selected product (if provided)
      const productMatch = selectProduct
        ? item["productSelection"] === selectProduct
        : true; // Allow all items if no product filter selected

      return searchMatch && filterMatch && productMatch;
    });
  };

  const productCategoryOptions = [
    { value: "Insurance", label: "Insurance" },
    { value: "Free Demat Account", label: "Free Demat Account" },
    { value: "TPP", label: "TPP" },
  ];

  const insuranceOptions = [
    { value: "Health Insurance", label: "Health Insurance" },
    { value: "General Insurance", label: "General Insurance" },
    { value: "Life Insurance", label: "Life Insurance" },
  ];

  const dematAccountOptions = [
    { value: "Choice", label: "Choice" },
    { value: "Upstox", label: "Upstox" },
    { value: "Angel Broking", label: "Angel Broking" },
    { value: "Paytm Money", label: "Paytm Money" },
    { value: "Motilal Oswal", label: "Motilal Oswal" },
  ];

  const tppOptions = [
    { value: "TPMS", label: "TPMS" },
    { value: "Mutual Fund", label: "Mutual Fund" },
    { value: "PMS", label: "PMS" },
  ];

  const [productOptions, setProductOptions] = useState([]);

  useEffect(() => {
    switch (selectText) {
      case "Insurance":
        setProductOptions(insuranceOptions);
        break;
      case "Free Demat Account":
        setProductOptions(dematAccountOptions);
        break;
      case "TPP":
        setProductOptions(tppOptions);
        break;
      default:
        setProductOptions([]);
        break;
    }
  }, [selectText]);

  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      header: () =>
        React.createElement(
          "p",
          { className: "text-sm font-bold text-gray-600 dark:text-white" },
          "NAME"
        ),
      cell: (info) =>
        React.createElement(
          "p",
          { className: "text-sm font-bold text-navy-700 dark:text-white" },
          info.getValue()
        ),
    }),
    columnHelper.accessor("productCategory", {
      id: "productCategory",
      header: () =>
        React.createElement(
          "p",
          { className: "text-sm font-bold text-gray-600 dark:text-white" },
          "PRODUCT CATEGORY"
        ),
      cell: (info) =>
        React.createElement(
          "p",
          { className: "text-sm font-bold text-navy-700 dark:text-white" },
          info.getValue()
        ),
    }),
    columnHelper.accessor("productSelection", {
      id: "productSelection",
      header: () =>
        React.createElement(
          "p",
          { className: "text-sm font-bold text-gray-600 dark:text-white" },
          "PRODUCT SELECTION"
        ),
      cell: (info) =>
        React.createElement(
          "p",
          { className: "text-sm font-bold text-navy-700 dark:text-white" },
          info.getValue()
        ),
    }),
    columnHelper.accessor("status", {
      id: "status",
      header: () =>
        React.createElement(
          "p",
          { className: "text-sm font-bold text-gray-600 dark:text-white" },
          "STATUS"
        ),
      cell: (info) =>
        React.createElement(
          "div",
          { className: "flex items-center" },
          info.getValue() === "Trade Done"
            ? React.createElement(MdCheckCircle, {
                className: "me-1 text-green-500 dark:text-green-300",
              })
            : info.getValue() === "In Progress"
            ? React.createElement(MdPending, {
                className: "me-1 text-red-500 dark:text-red-300",
              })
            : info.getValue() === "Account Created"
            ? React.createElement(MdOutlineError, {
                className: "me-1 text-amber-500 dark:text-amber-300",
              })
            : null,
          React.createElement(
            "p",
            { className: "text-sm font-bold text-navy-700 dark:text-white" },
            info.getValue()
          )
        ),
    }),
    columnHelper.accessor("date", {
      id: "date",
      header: () =>
        React.createElement(
          "p",
          { className: "text-sm font-bold text-gray-600 dark:text-white" },
          "DATE"
        ),
      cell: (info) =>
        React.createElement(
          "p",
          { className: "text-sm font-bold text-navy-700 dark:text-white" },
          info.getValue()
        ),
    }),
    columnHelper.accessor("call", {
      id: "call",
      header: () =>
        React.createElement(
          "p",
          { className: "text-sm font-bold text-gray-600 dark:text-white" },
          "CALL"
        ),
      cell: (info) =>
        React.createElement(
          "p",
          { className: "text-sm font-bold text-navy-700 dark:text-white" },
          info.getValue()
        ),
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: () =>
        React.createElement(
          "p",
          { className: "text-sm font-bold text-gray-600 dark:text-white" },
          "EMAIL"
        ),
      cell: (info) =>
        React.createElement(
          "p",
          { className: "text-sm font-bold text-navy-700 dark:text-white" },
          info.getValue()
        ),
    }),
    columnHelper.accessor("address", {
      id: "address",
      header: () =>
        React.createElement(
          "p",
          { className: "text-sm font-bold text-gray-600 dark:text-white" },
          "ADDRESS"
        ),
      cell: (info) =>
        React.createElement(
          "p",
          { className: "text-sm font-bold text-navy-700 dark:text-white" },
          info.getValue()
        ),
    }),
    columnHelper.accessor("vendorName", {
      id: "vendorName",
      header: () =>
        React.createElement(
          "p",
          { className: "text-sm font-bold text-gray-600 dark:text-white" },
          " VENDOR NAME"
        ),
      cell: (info) =>
        React.createElement(
          "p",
          { className: "text-sm font-bold text-navy-700 dark:text-white" },
          info.getValue()
        ),
    }),
    columnHelper.accessor("progress", {
      id: "progress",
      header: () =>
        React.createElement(
          "p",
          { className: "text-sm font-bold text-gray-600 dark:text-white" },
          "PROGRESS"
        ),
      cell: (info) =>
        React.createElement(
          "div",
          { className: "flex items-center" },
          React.createElement(Progress, {
            width: "w-[108px]",
            value: info.getValue(),
          })
        ),
    }),
    columnHelper.accessor("action", {
      id: "action",
      header: () =>
        React.createElement(
          "p",
          { className: "text-sm font-bold text-gray-600 dark:text-white" },
          "ACTIONS"
        ),
      cell: (info) =>
        React.createElement(
          "div",
          { className: "flex items-center" },
          React.createElement(
            "button",
            {
              onClick: () => {
                setCurrentUserAction(info.getValue());
                onOpen();
              },
              className:
                "rounded-xl bg-gradient-to-br from-[#0083FE] to-[#00FFF0] px-5 py-1 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-[#0083FE]/50",
            },
            "Actions"
          )
        ),
    }),
  ];

  const [data, setData] = useState(() => [...tableData]);

  useEffect(() => {
    const filteredData = combineFilters(
      tableData,
      searchText,
      selectText,
      selectProduct
    );
    setData(filteredData);
    paginate(1);
  }, [searchText, selectText, tableData, selectProduct]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = table
    .getRowModel()
    .rows.slice(indexOfFirstRow, indexOfLastRow);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return React.createElement(
    "div",
    null,
    React.createElement(ModalCompo, {
      onOpen: onOpen,
      onClose: onClose,
      isOpen: isOpen,
      data: data,
      id: currentUserAction,
      setTableData: { setTableData },
    }),
    React.createElement(
      Card,
      { extra: "w-full h-full px-6 pb-6 overflow-x-auto" },
      React.createElement(
        "div",
        { className: "relative flex items-center justify-between pt-4" },
        React.createElement(
          "div",
          { className: "text-xl font-bold text-navy-700 dark:text-white" },
          "Deals Table"
        ),
        React.createElement(CardMenu, null)
      ),

      <div className="-mx-4 flex flex-wrap">
        <div className="mb-4 w-full px-4 md:w-1/2">
          <SearchBar text={searchText} setSearchText={setSearchText} />
        </div>
        <div className="mb-4 w-full px-4 md:w-1/2">
          <SearchFilter
            options={productCategoryOptions}
            onFilterChange={handleSearchFilterChange}
          />
        </div>

        <div className="mb-4 w-full px-4 md:w-1/2">
          <SearchFilter
            options={productOptions}
            onFilterChange={handleProductFilterChange}
          />
        </div>
      </div>,
      React.createElement(
        "div",
        { className: "mt-8 overflow-x-scroll" },
        React.createElement(
          "table",
          { className: "w-full" },
          React.createElement(
            "thead",
            null,
            table.getHeaderGroups().map((headerGroup) =>
              React.createElement(
                "tr",
                {
                  key: headerGroup.id,
                  className: "!border-px !border-gray-400",
                },
                headerGroup.headers.map((header) =>
                  React.createElement(
                    "th",
                    {
                      key: header.id,
                      colSpan: header.colSpan,
                      onClick: header.column.getToggleSortingHandler(),
                      className:
                        "cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start",
                    },
                    React.createElement(
                      "div",
                      {
                        className:
                          "items-center justify-between text-xs text-gray-200",
                      },
                      flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      ),
                      {
                        asc: "",
                        desc: "",
                      }[header.column.getIsSorted() || null] || null
                    )
                  )
                )
              )
            )
          ),
          React.createElement(
            "tbody",
            null,
            currentRows.map((row) =>
              React.createElement(
                "tr",
                { key: row.id },
                row.getVisibleCells().map((cell) =>
                  React.createElement(
                    "td",
                    {
                      key: cell.id,
                      className: "min-w-[150px] border-white/0 py-3  pr-4",
                    },
                    flexRender(cell.column.columnDef.cell, cell.getContext())
                  )
                )
              )
            )
          )
        )
      ),

      React.createElement(
        "div",
        { className: "mt-4 flex justify-end" },
        React.createElement(
          "button",
          {
            onClick: () => paginate(currentPage - 1),
            disabled: currentPage === 1,
            className: "mr-2 rounded bg-gray-200 px-3 py-1 text-gray-700",
          },
          "Previous"
        ),
        React.createElement(
          "button",
          {
            onClick: () => paginate(currentPage + 1),
            disabled: indexOfLastRow >= table.getRowModel().rows.length,
            className: "rounded bg-gray-200 px-3 py-1 text-gray-700",
          },
          "Next"
        )
      )
    )
  );
}

export default ComplexTable;