import api from "views/api";

export const fetchTransactionData = async (id) => {
  const response = await api.get(`/getTransactionById/${id}`);

  const data = await response.data;
  return data.map((transaction, index) => ({
    Transaction_id: transaction.id,
    TransactionDate: transaction.date,
    Amount: transaction.amount,
    Type: transaction.description==="profit" ? "Profit" : transaction.description==="loss" ? "Loss" : transaction.description==="withDrawFund" ? "Withdraw" : "Deposit",
    action: index + 1,
  }));
};