import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = ({ allowedRoles }) => {
  const user = JSON.parse(localStorage.getItem("user"));

  if (!user) {
    // If no user is logged in, redirect to the sign-in page
    return <Navigate to="/auth/sign-in" replace />;
  }

  if (!allowedRoles.includes(user.role)) {
    // If the user's role is not allowed, redirect them to their default dashboard
    switch (user.role) {
      case "admin":
      case "representative":
        return <Navigate to="/admin/default" replace />;
      case "vendor":
        return <Navigate to="/vendor/default" replace />;
        case "lead":
          return <Navigate to="/client/default" replace />;
      default:
        return <Navigate to="/auth/sign-in" replace />;
    }
  }

  // If the user's role is allowed, render the child routes
  return <Outlet />;
};

export default PrivateRoute;
