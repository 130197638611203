import api from "views/api";

const tableDataComplex = [];

export const fetchLeadData = async () => {
  const response = await api.get("/leads");

  const data = await response.data;

  return data.map((lead, index) => ({
      action: lead.id,
      name: lead.name,
      status: lead.lead_status,
      date: lead.createdAt,
      call: lead.mobile,
      progress: 50,
      vendorName: lead.createdByName,
      email: lead.email,
      address: lead.address,
      productCategory: lead.product_category,
      productSelection: lead.product_selection,
    })
);

};