import React, { useEffect, useState } from "react";
import InputField from "../../../components/fields/InputField";
import api from "views/api";
import "../../../css/spinner.css";
import { useNavigate, useParams } from "react-router-dom";

function Form() {
  const { id } = useParams();
  const [currentLead, setCurrentLead] = useState({});
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    adharNumber: "",
    panNumber: "",
    bankName: "",
    ifscCode: "",
    accountNumber: "",
    fullNameAsPerBank: "",
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/lead/${id}`);
        const lead = response.data;
        setCurrentLead(lead);

        setFormData({
          email: lead.email,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const {
      adharNumber,
      panNumber,
      bankName,
      ifscCode,
      accountNumber,
      fullNameAsPerBank,
    } = formData;
    const tpmsData = {
      leadUuid: currentLead.uuid,
      adharNumber,
      panNumber,
      bankName,
      ifscCode,
      accountNumber,
      fullNameAsPerBank,
    };
    console.log(tpmsData);
    try {
      const response = await api.post("/tpms", tpmsData);
      console.log(response);

      if (response.status === 201) {
        alert("TPMS created successfully!");
        setFormData({
          email: "",
          adharNumber: "",
          panNumber: "",
          bankName: "",
          ifscCode: "",
          accountNumber: "",
          fullNameAsPerBank: "",
        });
        navigate("admin/data-tables");
      } else {
        alert(`Error: ${response.data.message}`);
      }
    } catch (error) {
      alert(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="mt-5 h-full w-full flex-col items-center justify-center rounded-[10px] border-[1px] border-gray-200 bg-white bg-clip-border px-6 pb-6 pt-4 shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none sm:overflow-x-auto">
          <div className="mb-3">
            <InputField
required={true}
              id="email"
              name="email"
              label="Email"
              extra=""
              placeholder="Enter your email"
              variant="auth"
              type="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <InputField
required={true}
              id="last-name"
              name="adharNumber"
              label="Adhar Number"
              extra=""
              placeholder="Enter Adhar Number"
              variant="auth"
              type="text"
              value={formData.adharNumber}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <InputField
required={true}
              id="panNumber"
              name="panNumber"
              label="PAN Number"
              extra=""
              placeholder="Enter PAN Number"
              variant="auth"
              type="text"
              value={formData.panNumber}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <InputField
required={true}
              id="bankName"
              name="bankName"
              label="Bank Name"
              extra=""
              placeholder="Enter Bank Name"
              variant="auth"
              type="text"
              value={formData.bankName}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <InputField
required={true}
              id="ifscCode"
              name="ifscCode"
              label="IFSC Code"
              extra=""
              placeholder="Enter IFSC Code"
              variant="auth"
              type="text"
              value={formData.ifscCode}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <InputField
required={true}
              id="accountNumber"
              name="accountNumber"
              label="Bank Account Number"
              extra=""
              placeholder="Enter Bank Account Number"
              variant="auth"
              type="text"
              value={formData.accountNumber}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <InputField
required={true}
              id="fullNameAsPerBank"
              name="fullNameAsPerBank"
              label="Full Name As Per Bank"
              extra=""
              placeholder="Enter Full Name As Per Bank"
              variant="auth"
              type="text"
              value={formData.fullNameAsPerBank}
              onChange={handleChange}
            />
          </div>
        </div>
        <button
          type="submit"
          className="m-5 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
          disabled={loading}
        >
          {loading ? <div className="spinner"></div> : "Submit"}
        </button>
      </form>
    </>
  );
}

export default Form;
