import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const PublicRoute = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();

  if (location.pathname === "/auth/logout") {
    // Allow access to the logout page
    return <Outlet />;
  }

  if (user) {
    switch (user.role) {
      case "admin":
      case "representative":
        return <Navigate to="/admin/default" replace />;
      case "vendor":
        return <Navigate to="/vendor/default" replace />;
      case "lead":
          return <Navigate to="/client/default" replace />;
      default:
        return <Navigate to="/admin/default" replace />;
    }
  }

  // If no user is logged in, render the child routes
  return <Outlet />;
};

export default PublicRoute;
