import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/modal";
import { useDisclosure } from "@chakra-ui/hooks";
import { useEffect, useState } from "react";
import DropDownCompo from "./DropdownCompo";
import api from "views/api";

const ModalCompo = ({ onOpen, isOpen, onClose, data, id, setTableData }) => {
  const [currentUserData, setCurrentUserData] = useState({});
  const [selectedState, setSelectedState] = useState(null);

  useEffect(() => {
    const user = data.find((user) => user.action === id);
    if (user) {
      setCurrentUserData(user);
    }
  }, [id, data]);

  const handleStateSelect = (state) => {
    setSelectedState(state);
    console.log("Selected state:", state);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const wrId = currentUserData.withdrawalReqestId;
    const status = selectedState;
    const statusData = {
      id: wrId,
      status,
    };

    try {
      console.log(statusData)
      const response = await api.patch(
        "/withdrawalrequests/status",
        statusData
      );

      
      if (response.status == 200) {
        alert("Status Changed SuccessFully!");
        setTableData((prevData) =>
          prevData.map((item) =>
            item.action === id ? { ...item, status: status } : item
          )
        );
        onClose();
      } else {
        alert("Status could not change");
      }
    } catch (error) {
      alert("Failed to change status");
      console.error(error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="!z-[1010]">
      <ModalOverlay className="bg-[#000] !opacity-30" />
      <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh] ">
        <ModalBody>
          <div className="!z-[1004] mt-[150px] flex max-w-[450px] flex-col rounded bg-white px-[30px] pb-[40px] pt-[35px] dark:bg-navy-400">
            <h1 className="mb-[20px] text-2xl font-bold dark:text-white">
              {currentUserData?.name}
            </h1>
            <p className="mb-[20px]">
              Current Status: <span>{currentUserData?.status}</span>
            </p>
            <div>
              <DropDownCompo onSelect={handleStateSelect} />
            </div>
            <div className="flex gap-2">
              <button
                onClick={onClose}
                className="linear rounded-xl border-2 border-red-500 px-5 py-3 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
              >
                Close
              </button>
              <button
                onClick={handleSubmit}
                className="rounded-xl bg-gradient-to-br from-brandLinear to-blueSecondary px-5 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-blueSecondary/50"
              >
                Save
              </button>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalCompo;
