import avatar from "assets/img/avatars/avatar8.png";
import banner from "assets/img/profile/banner.png";
import Card from "components/card";
import { useEffect, useState } from "react";

const 
Banner = ({isAdmin, currentTPMS}) => {

  const [name, setName] = useState("Name");

  

  useEffect(() => {
      const representativeName = currentTPMS.lead?.name;
      setName(representativeName);
  }, [isAdmin, currentTPMS])

  return (
    <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
      {/* Background and profile */}
      <div
        className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700">
          <img className="h-full w-full rounded-full" src={avatar} alt="" />
        </div>
      </div>

      {/* Name and position */}
      <div className="mt-16 flex flex-col items-center">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          {name}
        </h4>
        <p className="text-base font-normal text-gray-600">TPMS Client</p>
      </div>

      {/* Post followers */}
      <div className="mb-3 mt-6 flex items-start gap-2 md:!gap-6">
        <div className="flex flex-col items-center justify-center">
          <p className="text-xl font-bold text-navy-700 dark:text-white">
            ₹{currentTPMS?.tpms?.depositedAmount}
          </p>
          <p className="text-center text-sm font-normal text-gray-600">
            Deposited Amount
          </p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <p className="text-xl font-bold text-navy-700 dark:text-white">
            ₹{currentTPMS?.tpms?.pnlamount}(
            {Math.round(
              (currentTPMS?.tpms?.pnlamount * 100) /
                currentTPMS?.tpms?.depositedAmount
            )}%)
          </p>
          <p className="text-center text-sm font-normal text-gray-600">
            P & L{" "}
          </p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <p className="text-xl font-bold text-navy-700 dark:text-white">
            {" "}
            ₹
            {currentTPMS?.tpms?.pnlamount > 0
              ? currentTPMS?.tpms?.pnlamount +
                currentTPMS?.tpms?.depositedAmount
              : 0}
          </p>
          <p className="text-center text-sm font-normal text-gray-600">
            Withdrawable Amount
          </p>
        </div>
      </div>
    </Card>
  );
};

export default Banner;
