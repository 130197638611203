import React, { useState } from "react";
import Card from "components/card";
import InputField from "components/fields/InputField";
import api from "views/api";

const AddTransaction = ({ currentTPMS }) => {
  const [selected, setSelected] = useState("addFund");
  const [statusSelected, setStatusSelected] = useState("profit");

  const [formData, setFormData] = useState({
    amount: "",
    transactionId: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let endpoint = "";
      if (selected === "addFund") {
        endpoint = "/deposit";
      } else if (selected === "withDrawFund") {
        endpoint = "/withdraw";
      } else if (selected === "addPnL") {
        endpoint = "/addProfitLoss";
      }

      const { amount, transactionId } = formData;
      const tpmsId = currentTPMS.tpms.id; // Assuming the user object contains the tpmsId
      const description = selected === "addPnL" ? statusSelected : selected;

      const transactionData = {
        transactionId,
        tpmsId,
        amount: parseFloat(amount),
        description,
      };

      await api.post(endpoint, transactionData);
      alert("Transaction completed successfully!");
    } catch (error) {
      alert("Failed to complete transaction");
      console.error(error);
    } finally {
      setLoading(false);
      setFormData({ amount: "", transactionId: "" });
    }
  };

  return (
    <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
      <div className="flex">
        <button
          onClick={() => setSelected("addFund")}
          className={`m-1 rounded px-4 py-1 font-bold hover:bg-blue-500 ${
            selected === "addFund"
              ? "bg-blue-500 text-white"
              : "border border-blue-500 bg-white text-blue-500 hover:text-white"
          }`}
        >
          Add Fund
        </button>
        <button
          onClick={() => setSelected("withDrawFund")}
          className={`m-1 rounded px-4 py-1 font-bold hover:bg-blue-500 ${
            selected === "withDrawFund"
              ? "bg-blue-500 text-white"
              : "border border-blue-500 bg-white text-blue-500 hover:text-white"
          }`}
        >
          Withdraw Fund
        </button>
        <button
          onClick={() => setSelected("addPnL")}
          className={`m-1 rounded px-4 py-1 font-bold hover:bg-blue-500 ${
            selected === "addPnL"
              ? "bg-blue-500 text-white"
              : "border border-blue-500 bg-white text-blue-500 hover:text-white"
          }`}
        >
          Add PnL
        </button>
      </div>

      {selected === "addFund" && (
        <form onSubmit={handleSubmit} className="w-full">
          <div className="mb-3">
            <InputField
              id="transactionId"
              name="transactionId"
              label="Transaction ID"
              extra=""
              placeholder="Enter transaction ID"
              variant="auth"
              type="text"
              value={formData.transactionId}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <InputField
              id="amount"
              name="amount"
              label="Amount"
              extra=""
              placeholder="Enter amount to add fund"
              variant="auth"
              type="text"
              value={formData.amount}
              onChange={handleChange}
            />
          </div>
          <button
            type="submit"
            className="m-5 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-500"
            disabled={loading}
          >
            {loading ? <div className="spinner"></div> : "Submit"}
          </button>
        </form>
      )}
      {selected === "withDrawFund" && (
        <form onSubmit={handleSubmit} className="w-full">
          <div className="mb-3">
            <InputField
              id="transactionId"
              name="transactionId"
              label="Transaction ID"
              extra=""
              placeholder="Enter transaction ID"
              variant="auth"
              type="text"
              value={formData.transactionId}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <InputField
              id="amount"
              name="amount"
              label="Amount"
              extra=""
              placeholder="Enter amount to withdraw fund"
              variant="auth"
              type="text"
              value={formData.amount}
              onChange={handleChange}
            />
          </div>
          <button
            type="submit"
            className="m-5 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-500"
            disabled={loading}
          >
            {loading ? <div className="spinner"></div> : "Submit"}
          </button>
        </form>
      )}
      {selected === "addPnL" && (
        <div className="mt-5 w-full">
          <div className="flex w-full justify-center">
            <button
              type="button"
              onClick={() => setStatusSelected("profit")}
              className={`m-1 rounded px-4 py-1 font-bold hover:bg-green-500 ${
                statusSelected === "profit"
                  ? "bg-green-500 text-white"
                  : "border border-green-500 bg-white text-green-500 hover:text-white"
              }`}
            >
              Profit
            </button>
            <button
              type="button"
              onClick={() => setStatusSelected("loss")}
              className={`m-1 rounded px-4 py-1 font-bold hover:bg-red-500 ${
                statusSelected === "loss"
                  ? "bg-red-500 text-white"
                  : "border border-red-500 bg-white text-red-500 hover:text-white"
              }`}
            >
              Loss
            </button>
          </div>
          {statusSelected === "profit" && (
            <form onSubmit={handleSubmit} className="w-full">
              <div className="mb-3">
                <InputField
                  id="transactionId"
                  name="transactionId"
                  label="Transaction ID"
                  extra=""
                  placeholder="Enter transaction ID"
                  variant="auth"
                  type="text"
                  value={formData.transactionId}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <InputField
                  id="amount"
                  name="amount"
                  label="Amount"
                  extra=""
                  placeholder="Enter Profit amount"
                  variant="auth"
                  type="text"
                  value={formData.amount}
                  onChange={handleChange}
                />
              </div>
              <button
                type="submit"
                className="m-5 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-500"
                disabled={loading}
              >
                {loading ? <div className="spinner"></div> : "Submit"}
              </button>
            </form>
          )}
          {statusSelected === "loss" && (
            <form onSubmit={handleSubmit} className="w-full">
              <div className="mb-3">
                <InputField
                  id="transactionId"
                  name="transactionId"
                  label="Transaction ID"
                  extra=""
                  placeholder="Enter transaction ID"
                  variant="auth"
                  type="text"
                  value={formData.transactionId}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <InputField
                  id="amount"
                  name="amount"
                  label="Amount"
                  extra=""
                  placeholder="Enter Loss amount"
                  variant="auth"
                  type="text"
                  value={formData.amount}
                  onChange={handleChange}
                />
              </div>
              <button
                type="submit"
                className="m-5 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-500"
                disabled={loading}
              >
                {loading ? <div className="spinner"></div> : "Submit"}
              </button>
            </form>
          )}
        </div>
      )}
    </Card>
  );
};

export default AddTransaction;
