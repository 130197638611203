import React, { useState } from "react";
import InputField from "../../../components/fields/InputField";
import api from "views/api";
import "../../../css/spinner.css";
import Checkbox from "components/checkbox";

function Form() {
  const [showPassword, setShowPassword] = useState(false);
  const [isDummy, setIsDummy] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    address: "",
    password: "",
    confPassword: "",
  });

  const handleCheckboxChange = () => {
    setShowPassword(!showPassword);
  };

  const handleIsDummyChange = () => {
    setIsDummy(!isDummy);
  };

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAddressChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      address: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const {
      firstName,
      lastName,
      email,
      mobile,
      address,
      password,
      confPassword,
    } = formData;

    if (password !== confPassword) {
      alert("Passwords do not match");
      setLoading(false);
      return;
    }

    const fullName = `${firstName} ${lastName}`;
    const user = JSON.parse(localStorage.getItem("user"));
    const createdBy = user.uuid;
    const vendorData = {
      name: fullName,
      email,
      mobile,
      address,
      password,
      confPassword,
      createdBy,
      isDummy  // Include isDummy flag
    };

    try {
      await api.post("/vendor", vendorData);
      alert("Vendor added successfully!");
    } catch (error) {
      alert("Failed to add vendor");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-5 h-full w-full flex-col items-center justify-center rounded-[10px] border-[1px] border-gray-200 bg-white bg-clip-border px-6 pb-6 pt-4 shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none sm:overflow-x-auto">
        <div className="mb-3">
          <InputField
            required={true}
            id="first-name"
            name="firstName"
            label="First Name"
            extra=""
            placeholder="Enter your first name"
            variant="auth"
            type="text"
            value={formData.firstName}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <InputField
            required={true}
            id="last-name"
            name="lastName"
            label="Last Name"
            extra=""
            placeholder="Enter your last name"
            variant="auth"
            type="text"
            value={formData.lastName}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <InputField
            required={true}
            id="email"
            name="email"
            label="Email"
            extra=""
            placeholder="Enter your email"
            variant="auth"
            type="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <InputField
            required={true}
            id="mobile"
            name="mobile"
            label="Mobile"
            extra=""
            placeholder="Enter your mobile number"
            variant="auth"
            type="tel"
            minLength={10}
            value={formData.mobile}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label
            htmlFor="address"
            className="form-label text-sm font-bold text-navy-700 dark:text-white"
          >
            Address
          </label>
          <textarea
            id="address"
            name="address"
            placeholder="Enter your address"
            className="form-control mt-2 flex h-32 w-full rounded-xl border border-gray-200 bg-white/0 p-3 text-sm outline-none dark:!border-white/10 dark:text-white"
            value={formData.address}
            onChange={handleAddressChange}
          />
        </div>
        <div className="mb-3">
          <InputField
            required={true}
            id="password"
            name="password"
            label="Password"
            extra=""
            placeholder="Enter your password"
            variant="auth"
            type={showPassword ? "text" : "password"}
            value={formData.password}
            minLength={8}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox
              color="blue"
              extra="mr-2"
              checked={showPassword}
              onChange={() => handleCheckboxChange()}
            />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Show Password
            </p>
          </div>
        </div>
        <div className="mb-3">
          <InputField
            required={true}
            id="confPassword"
            name="confPassword"
            label="Confirm Password"
            extra=""
            placeholder="Confirm your password"
            variant="auth"
            type={showPassword ? "text" : "password"}
            minLength={8}
            value={formData.confPassword}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox
              color="red"
              extra="mr-2"
              checked={isDummy}
              onChange={() => handleIsDummyChange()}
            />
            <p className="ml-2 text-md font-medium text-red-700 dark:text-white">
              Mark as Dummy Vendor
            </p>
          </div>
        </div>
      </div>
      <button
        type="submit"
        className="m-5 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
        disabled={loading}
      >
        {loading ? <div className="spinner"></div> : "Submit"}
      </button>
    </form>
  );
}

export default Form;
