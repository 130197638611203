import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default/index.jsx";
import DataTables from "views/admin/tables";
import Profile from "views/admin/profile";
import VendorForm from "views/admin/forms/index.jsx";

// Vendor Imports
import MainDashboardV from "views/vendor/default/index.jsx";
import LeadsTable from "views/vendor/tables";
import ProfileV from "views/vendor/profile";
import LeadForm from "views/vendor/forms/index.jsx";
// client Imports
import MainDashboardC from "views/client/default/index.jsx";
import LeadsTableC from "views/client/tables";
import ProfileC from "views/client/profile/index.jsx";
import WithdrawalForm from "views/client/forms/index.jsx";

// Auth Imports

import Logout from "views/auth/LogOut";
import ChangePassword from "views/auth/ChangePassword";

// Icon Imports
import {
  MdHome,
  MdBarChart,
  MdPerson,
  MdLock,
  MdOutlinePersonAdd,
} from "react-icons/md";


const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
    allowedRoles: ["admin", "representative"],
  },
  {
    name: "Data Tables",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "data-tables",
    component: <DataTables />,
    allowedRoles: ["admin", "representative"],
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
    allowedRoles: ["admin", "representative"],
  },
  {
    name: "Add Vendor",
    layout: "/admin",
    path: "vendor-form",
    icon: <MdOutlinePersonAdd className="h-6 w-6" />,
    component: <VendorForm />,
    allowedRoles: ["admin", "representative"],
  },
  {
    name: "Change Password",
    layout: "/admin",
    path: "changePassword",
    icon: <MdLock className="h-6 w-6" />,
    component: <ChangePassword />,
    allowedRoles: ["admin", "representative"],
  },
  {
    name: "Vendor Dashboard",
    layout: "/vendor",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboardV />,
    allowedRoles: ["vendor"],
  },
  {
    name: "Data Tables",
    layout: "/vendor",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "data-tables",
    component: <LeadsTable />,
    allowedRoles: ["vendor"],
  },
  {
    name: "Profile",
    layout: "/vendor",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <ProfileV />,
    allowedRoles: ["vendor"],
  },
  {
    name: "Add Lead",
    layout: "/vendor",
    path: "lead-form",
    icon: <MdOutlinePersonAdd className="h-6 w-6" />,
    component: <LeadForm />,
    allowedRoles: ["vendor"],
  },
  {
    name: "Change Password",
    layout: "/vendor",
    path: "changePassword",
    icon: <MdLock className="h-6 w-6" />,
    component: <ChangePassword />,
    allowedRoles: ["admin", "representative"],
  },
  



  // Lead Routes 

  {
    name: "TPMS Dashboard",
    layout: "/client",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboardC />,
    allowedRoles: ["lead"],
  },
  
  {
    name: "Profile",
    layout: "/client",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <ProfileC />,
    allowedRoles: ["lead"],
  },
  {
    name: "Withdrawal Request",
    layout: "/client",
    path: "withdrawal",
    icon: <MdOutlinePersonAdd className="h-6 w-6" />,
    component: <WithdrawalForm />,
    allowedRoles: ["lead"],
  },

  {
    name: "Change Password",
    layout: "/client",
    path: "changePassword",
    icon: <MdLock className="h-6 w-6" />,
    component: <ChangePassword />,
    allowedRoles: ["lead"],
  }
  ,
  

 

  {
    name: "Logout",
    layout: "/auth",
    path: "logout",
    icon: <MdLock className="h-6 w-6" />,
    component: <Logout />,
    allowedRoles: ["admin", "representative", "vendor", "lead"],
  },
];

export default routes;
