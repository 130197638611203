import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Routes, Route, Navigate } from "react-router-dom";
import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import VendorLayout from "layouts/vendor";
import ClientLayout from "layouts/client";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Logout from "views/auth/LogOut";
import { isTokenExpired } from "./utils/auth.jsx";

const App = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (isTokenExpired(token)) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      navigate("/auth/sign-in");
    }
  }, [navigate]);

  return (
    <Routes>
      <Route element={<PublicRoute />}>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route path="auth/logout" element={<Logout />} />
      </Route>

      <Route
        element={<PrivateRoute allowedRoles={["admin", "representative",]} />}
      >
        <Route path="admin/*" element={<AdminLayout />} />
      </Route>

      <Route element={<PrivateRoute allowedRoles={["vendor"]} />}>
        <Route path="vendor/*" element={<VendorLayout />} />
      </Route>

     

      <Route element={<PrivateRoute allowedRoles={["lead"]} />}>
        <Route path="client/*" element={<ClientLayout />} />
      </Route>

      <Route path="rtl/*" element={<RtlLayout />} />

      <Route path="/" element={<Navigate to="/admin/default" replace />} />
    </Routes>
  );
};

export default App;
