import AccountDetails from "./components/AccountDetails";
import Banner from "./components/Banner.jsx";
import Table from "../tables/index";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "views/api";
import AddTransaction from "./components/AddTransaction";

const ProfileOverview = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const { id } = useParams();
  const [currentTPMS, setCurrentTPMS] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("Started fetching");
        const response = await api.get(`/tpms/${id}`);
        const tpms = response.data;
        setCurrentTPMS(tpms);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    const fetchDataForTPMS = async (uuid) => {
      try {
        console.log("Started fetching");
        const response = await api.get(`/tpmsbyuuid/${uuid}`);
        const tpms = response.data;
        setCurrentTPMS(tpms);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    if (id) {
      setIsAdmin(true);
      fetchData();
    } else {
      const user = JSON.parse(localStorage.getItem("user") || "{}");
      const userId = user.uuid;
      fetchDataForTPMS(userId);
      console.log(currentTPMS);
    }
  }, [id]);

  return (
    <>
      {loading ? (
        <div>Loading</div>
      ) : (
        <div className="flex w-full flex-col gap-5">
          <div className="w-ful mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">
            <div className="col-span-4 lg:!mb-0">
              <Banner isAdmin={isAdmin} currentTPMS={currentTPMS} />
            </div>
            {isAdmin && (
              <div className="col-span-4 lg:!mb-0">
                <AddTransaction currentTPMS={currentTPMS} />
              </div>
            )}
            <div className="col-span-4 lg:!mb-0">
              <AccountDetails currentTPMS={currentTPMS} />
            </div>

            {/* <div className="col-span-3 lg:!mb-0">
          <Storage />
        </div> */}

            {/* <div className="z-0 col-span-5 lg:!mb-0">
          <Upload />
        </div> */}
          </div>
          {/* all project & ... */}

          <div className="h-full grid-cols-1 gap-5 lg:!grid-cols-12">
            {/* <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-4">
          <Project />
        </div>


        

        <div className="col-span-4 lg:!mb-0">
          <AccountDetails />
        </div>
        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-5">
          <General />
        </div> */}

            {/* <div className="col-span-5 lg:col-span-12 lg:mb-0 3xl:!col-span-3">
          <Notification />
        </div> */}

            <div className="col-span-4 lg:!mb-0">
              <Table isAdmin={isAdmin} currentTPMS={currentTPMS} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileOverview;
