// utils/auth.js
export const isTokenExpired = (token) => {
    if (!token) return true;
  
    const tokenParts = token.split(".");
    if (tokenParts.length !== 3) return true;
  
    try {
      const payload = JSON.parse(atob(tokenParts[1]));
      const expiry = payload.exp * 1000; // JWT exp is in seconds, convert to milliseconds
      return Date.now() > expiry;
    } catch (error) {
      return true;
    }
  };
  