import { IoMdHome } from "react-icons/io";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";
import Widget from "components/widget/Widget.jsx";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "views/api";

const Dashboard = () => {
  const navigate = useNavigate();

  const [currentTPMS, setCurrentTPMS] = useState({});
  const [loading, setLoading] = useState(true);

  const [depositedAmount, setDepositedAmount] = useState(0);
  const [profitAndLoss, setProfitAndLoss] = useState(0);
  const [tradingBalance, setTradingBalance] = useState(0);
  const [withdrawableAmount, setWithdrawableAmount] = useState(0);
  const [TotaltradingAmount, setTotaltradingAmount] = useState(0);
  const goToWithdrawal = () => {
    navigate("/client/withdrawal");
  };

  useEffect(() => {
    const fetchDataForTPMS = async (uuid) => {
      try {
        console.log("Started fetching");
        const response = await api.get(`/tpmsbyuuid/${uuid}`);
        const tpms = response.data;
        setCurrentTPMS(tpms);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const userId = user.uuid;
    fetchDataForTPMS(userId);
    console.log(currentTPMS);
  }, []);

  useEffect(() => {
    if (currentTPMS?.tpms?.id) {
      setDepositedAmount(currentTPMS?.tpms?.depositedAmount);
      setProfitAndLoss(currentTPMS?.tpms?.pnlamount + "(" + 
            Math.round(
              (currentTPMS?.tpms?.pnlamount * 100) /
                currentTPMS?.tpms?.depositedAmount) +
            "%)");
      setWithdrawableAmount(
        currentTPMS?.tpms?.pnlamount > 0
          ? currentTPMS?.tpms?.pnlamount + currentTPMS?.tpms?.depositedAmount
          : 0
      );
      setTotaltradingAmount(
        currentTPMS?.tpms?.pnlamount + currentTPMS?.tpms?.depositedAmount
      );
    }
  }, [currentTPMS]);

  return (
    <div>
      {/* Card widget */}
      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Deposited Amount ₹ "}
          subtitle={"₹" + depositedAmount}
        />
        <Widget
          icon={<IoDocuments className="h-6 w-6" />}
          title={"Profit and Loss"}
          subtitle={"₹" + profitAndLoss}
        />
        <Widget
          icon={<MdDashboard className="h-6 w-6" />}
          title={"Withdrawable Amount"}
          subtitle={"₹" + withdrawableAmount}
          func={goToWithdrawal}
        />
        <Widget
          icon={<MdDashboard className="h-6 w-6" />}
          title={"Total Trading Amount"}
          subtitle={"₹" + TotaltradingAmount}
          // func={goToWithdrawal}
        />
      </div>

      {/* Additional layout and components can be added here */}
    </div>
  );
};

export default Dashboard;
