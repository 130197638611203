import api from "views/api";

export const fetchWithdrawalRequestData = async (id) => {
    const response = await api.get(`withdrawalrequests/${id}`);

    const data = await response.data;
    return data.map((wr, index) => ({
      withdrawalReqestId: wr.id,
      amount: wr.amount,
      status: wr.status,
      date: wr.createdAt,
      action: index + 1,
    }));
}