import React, { useEffect, useState } from "react";
import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import Progress from "components/progress";
import { MdCancel, MdCheckCircle, MdOutlineError } from "react-icons/md";
import { useDisclosure } from "@chakra-ui/hooks";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import VendorModalCompo from "./VendorModalCompo.jsx";
import SearchBar from "./SearchBar.jsx";

const columnHelper = createColumnHelper();

function ComplexTable({tableData}) {
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10); // Number of rows per page
  const [currentUserAction, setCurrentUserAction] = useState(0);
  const [searchText, setSearchText] = useState("");

  const { isOpen, onOpen, onClose } = useDisclosure();

  // let defaultData = tableData;
  const columns = [
    columnHelper.accessor("code", {
      id: "code",
      header: () => (
        React.createElement("p", { className: "text-sm font-bold text-gray-600 dark:text-white" }, "VENDOR CODE")
      ),
      cell: (info) => (
        React.createElement("p", { className: "text-sm font-bold text-navy-700 dark:text-white" }, info.getValue())
      ),
    }),
    columnHelper.accessor("name", {
      id: "name",
      header: () => (
        React.createElement("p", { className: "text-sm font-bold text-gray-600 dark:text-white" }, "NAME")
      ),
      cell: (info) => (
        React.createElement("p", { className: "text-sm font-bold text-navy-700 dark:text-white" }, info.getValue())
      ),
    }),
    columnHelper.accessor("call", {
      id: "call",
      header: () => (
        React.createElement("p", { className: "text-sm font-bold text-gray-600 dark:text-white" }, "CALL")
      ),
      cell: (info) => (
        React.createElement("p", { className: "text-sm font-bold text-navy-700 dark:text-white" }, info.getValue())
      ),
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: () => (
        React.createElement("p", { className: "text-sm font-bold text-gray-600 dark:text-white" }, "EMAIL")
      ),
      cell: (info) => (
        React.createElement("p", { className: "text-sm font-bold text-navy-700 dark:text-white" }, info.getValue())
      ),
    }),
    columnHelper.accessor("address", {
      id: "address",
      header: () => (
        React.createElement("p", { className: "text-sm font-bold text-gray-600 dark:text-white" }, "ADDRESS")
      ),
      cell: (info) => (
        React.createElement("p", { className: "text-sm font-bold text-navy-700 dark:text-white" }, info.getValue())
      ),
    }),
    columnHelper.accessor("action", {
      id: "action",
      header: () => (
        React.createElement("p", { className: "text-sm font-bold text-gray-600 dark:text-white" }, "ACTIONS")
      ),
      cell: (info) => (
        React.createElement("div", { className: "flex items-center" },
          React.createElement("button", {
            onClick: () => {
              setCurrentUserAction(info.getValue());
              onOpen();
            },
            className: "rounded-xl bg-gradient-to-br from-[#0083FE] to-[#00FFF0] px-5 py-1 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-[#0083FE]/50"
          }, "Actions")
        )
      ),
    }),
  ];

  const [data, setData] = useState(() => [...tableData]);

    useEffect(() => {
    paginate(1)
    if (searchText) {
      const filteredData = tableData.filter(item =>
        Object.values(item).some(
          val => val && val.toString().toLowerCase().includes(searchText.toLowerCase())
        )
      );
      setData(filteredData);
    } else {
      setData(tableData);
    }
  }, [searchText, tableData]);

  useEffect(() => {
    setData(tableData);
  }, [tableData])

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = table
    .getRowModel()
    .rows.slice(indexOfFirstRow, indexOfLastRow);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return React.createElement(
    "div",
    null,
    React.createElement(VendorModalCompo, {
      onOpen: onOpen,
      onClose: onClose,
      isOpen: isOpen,
      data: data,
      id: currentUserAction,
    }),
    React.createElement(
      Card,
      { extra: "w-full h-full px-6 pb-6 overflow-x-auto" },
      React.createElement(
        "div",
        { className: "relative flex items-center justify-between pt-4" },
        React.createElement(
          "div",
          { className: "text-xl font-bold text-navy-700 dark:text-white" },
          "Vendors Table"
        ),
        React.createElement(CardMenu, null)
      ),

      React.createElement(SearchBar, {
        text: searchText,
        setSearchText: setSearchText,
      }),

      React.createElement(
        "div",
        { className: "mt-8 overflow-x-scroll" },
        React.createElement(
          "table",
          { className: "w-full" },
          React.createElement(
            "thead",
            null,
            table.getHeaderGroups().map((headerGroup) =>
              React.createElement(
                "tr",
                {
                  key: headerGroup.id,
                  className: "!border-px !border-gray-400",
                },
                headerGroup.headers.map((header) =>
                  React.createElement(
                    "th",
                    {
                      key: header.id,
                      colSpan: header.colSpan,
                      onClick: header.column.getToggleSortingHandler(),
                      className:
                        "cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start",
                    },
                    React.createElement(
                      "div",
                      {
                        className:
                          "items-center justify-between text-xs text-gray-200",
                      },
                      flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      ),
                      {
                        asc: "",
                        desc: "",
                      }[header.column.getIsSorted() || null] || null
                    )
                  )
                )
              )
            )
          ),
          React.createElement(
            "tbody",
            null,
            currentRows.map((row) =>
              React.createElement(
                "tr",
                { key: row.id },
                row.getVisibleCells().map((cell) =>
                  React.createElement(
                    "td",
                    {
                      key: cell.id,
                      className: "min-w-[150px] border-white/0 py-3  pr-4",
                    },
                    flexRender(cell.column.columnDef.cell, cell.getContext())
                  )
                )
              )
            )
          )
        )
      ),

      React.createElement(
        "div",
        { className: "mt-4 flex justify-end" },
        React.createElement(
          "button",
          {
            onClick: () => paginate(currentPage - 1),
            disabled: currentPage === 1,
            className: "mr-2 rounded bg-gray-200 px-3 py-1 text-gray-700",
          },
          "Previous"
        ),
        React.createElement(
          "button",
          {
            onClick: () => paginate(currentPage + 1),
            disabled: indexOfLastRow >= table.getRowModel().rows.length,
            className: "rounded bg-gray-200 px-3 py-1 text-gray-700",
          },
          "Next"
        )
      )
    )
  );
}

export default ComplexTable;
