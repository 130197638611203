import React, { useState } from "react";

interface CustomDropdownProps {
  label: string;
  options: string[];
  selectedOption: string;
  onChange: (value: string) => void;
}

function CustomDropdown({
  label,
  options,
  selectedOption,
  onChange,
}: CustomDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectOption = (option: string) => {
    onChange(option);
    setIsOpen(false);
  };

  return (
    <div className="custom-dropdown">
      <label className="text-sm text-navy-700 dark:text-white ">{label}</label>
      <div
        className="mt-2 flex h-12 w-full rounded-xl border bg-white/0 p-3 text-sm outline-none"
        onClick={toggleDropdown}
      >
        {selectedOption || "Select"}
      </div>
      {isOpen && (
        <div className="options mt-2 shadow-xl">
          {options.map((option: string, index: number) => (
            <div
              key={index}
              className="flex h-12 w-full border bg-white/0 p-3 text-sm outline-none"
              onClick={() => selectOption(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default CustomDropdown;
