import React, { useState } from 'react';

const SearchFilter = ({ options, onFilterChange }) => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    onFilterChange(event.target.value);
  };

  return (
    <div className="flex items-center">
      <label htmlFor="search-filter" className="mr-2 text-sm font-medium text-gray-700 dark:text-gray-200">
        Product Category
      </label>
      <select
        id="search-filter"
        value={selectedOption}
        onChange={handleOptionChange}
        className="rounded border border-gray-300 py-2 px-3 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
      >
        <option value="">All</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SearchFilter;
