import React, { useEffect, useState } from "react";
import { fetchLeadData } from "./variables/tableDataLeads.jsx";
import LeadsTable from "./components/LeadsTable.jsx";
import DealsTable from "./components/DealsTable.jsx";

const Tables = () => {
  const [tableDataLeads, setTableDataLeads] = useState([]);
  const [tableDataDeals, setTableDataDeals] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("Fetching data...");
        const data = await fetchLeadData();
        setTableDataLeads(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching vendor data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filteredDeals = tableDataLeads.filter(
      (lead) =>
        lead.status === "Trade Done" || lead.status === "Account Created"
    );
    setTableDataDeals(filteredDeals);
  }, [tableDataLeads]);


  return (
    <div className="mt-5 ">
      <div className="mt-5 ">
        <LeadsTable tableData={tableDataLeads} />
      </div>
      <div className="mt-5 ">
        <DealsTable tableData={tableDataDeals} />
      </div>
    </div>
  );
};

export default Tables;
