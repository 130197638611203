import React, { useState, useEffect } from "react";
import Card from "components/card";

const AccountDetails = ({currentTPMS}) => {
  const [accountInfo, setAccountInfo] = useState({
    adharNo: "",
    panNo: "",
    bankName: "",
    fullNameAsPerBank: "",
    ifscCode: "",
    accountNo: "",
  });

  useEffect(() => {
    setAccountInfo({
      adharNo: currentTPMS?.tpms?.adharNumber || "1234 5678 9012",
      panNo: currentTPMS?.tpms?.panNumber || "ABCDE1234F",
      bankName: currentTPMS?.tpms?.bankName || "State Bank of India",
      fullNameAsPerBank: currentTPMS?.tpms?.fullNameAsPerBank || "John Doe",
      ifscCode: currentTPMS?.tpms?.ifscCode || "SBIN0001234",
      accountNo: currentTPMS?.tpms?.accountNumber || "1234 5678 9012",
    });
  }, []);

  return (
    <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
      <div className="w-full">
        {/* Account Details */}
        <div className="mb-4 w-full">
          <h4 className="mb-2 text-xl font-bold text-navy-700 dark:text-white">Account Details</h4>
          <div className="mb-2 flex flex-col">
            <span className="text-sm font-semibold text-gray-600 dark:text-white">Adhar No:</span>
            <span className="text-base font-normal text-navy-700 dark:text-gray-300">{accountInfo.adharNo}</span>
          </div>
          <div className="mb-2 flex flex-col">
            <span className="text-sm font-semibold text-gray-600 dark:text-white">Pan No:</span>
            <span className="text-base font-normal text-navy-700 dark:text-gray-300">{accountInfo.panNo}</span>
          </div>
        </div>
        
        {/* Bank Details */}
        <div className="w-full">
          <h4 className="mb-2 text-xl font-bold text-navy-700 dark:text-white">Bank Details</h4>
          <div className="mb-2 flex flex-col">
            <span className="text-sm font-semibold text-gray-600 dark:text-white">Bank Name:</span>
            <span className="text-base font-normal text-navy-700 dark:text-gray-300">{accountInfo.bankName}</span>
          </div>
          <div className="mb-2 flex flex-col">
            <span className="text-sm font-semibold text-gray-600 dark:text-white">Full Name As per Bank:</span>
            <span className="text-base font-normal text-navy-700 dark:text-gray-300">{accountInfo.fullNameAsPerBank}</span>
          </div>
          <div className="mb-2 flex flex-col">
            <span className="text-sm font-semibold text-gray-600 dark:text-white">IFSC Code:</span>
            <span className="text-base font-normal text-navy-700 dark:text-gray-300">{accountInfo.ifscCode}</span>
          </div>
          <div className="mb-2 flex flex-col">
            <span className="text-sm font-semibold text-gray-600 dark:text-white">Account No:</span>
            <span className="text-base font-normal text-navy-700 dark:text-gray-300">{accountInfo.accountNo}</span>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default AccountDetails;
