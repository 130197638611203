import Card from "components/card";
import {
  MdAdd,
  MdKeyboardArrowRight,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";

const Widget = ({ icon, title, subtitle, func, func2 }) => {
  return (
    <Card extra="!flex-row flex-grow items-center rounded-[20px]">
      <div className="relative flex flex-grow !flex-row flex-col items-center rounded-[20px]">
        <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center cursor-pointer">
          <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
            <span
              className="flex items-center text-brand-500 dark:text-white"
              onClick={func}
            >
              {icon}
            </span>
          </div>
        </div>

        <div className="h-50 ml-4 flex w-auto flex-col justify-center">
          <p className="font-dm text-sm font-medium text-gray-600">{title}</p>
          <h4 className="text-xl font-bold text-navy-700 dark:text-white">
            {subtitle}
          </h4>
        </div>
      </div>

      {func2!=null && <div className="mr-[18px] flex h-[90px] w-auto flex-row items-center cursor-pointer">
        <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
          <span
            className="text-black flex items-center dark:text-white"
            onClick={func2}
          >
            <MdAdd className="h-7 w-7" />
          </span>
        </div>
      </div>}
    </Card>
  );
};

export default Widget;
