import React from "react";
import { FiSearch } from "react-icons/fi";

function SearchBar({searchText, setSearchText}) {
  return (
      <div className="relative mt-[3px] flex h-[61px]  flex-grow px-2 py-2 ">
      <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white w-[325px]">
        <p className="pl-3 pr-2 text-xl">
          <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
        </p>
        <input
          type="text"
          placeholder="Search..."
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
          className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white pr-3"
        />
      </div>
    </div>
  );
}

export default SearchBar;
