import React, { useRef, useState, useEffect } from "react";

function useOutsideAlerter(ref, setIsOpen) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setIsOpen]);
}

const Dropdown = ({ button, children, classNames, isOpen, setIsOpen, animation }) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setIsOpen);

  return (
    <div ref={wrapperRef} className="relative inline-block">
      <div
        className="inline-block"
        onClick={() => setIsOpen(!isOpen)}
      >
        {button}
      </div>
      <div
        className={`${classNames} absolute z-10 ${
          animation
            ? animation
            : "origin-top-left transition-all duration-300 ease-in-out"
        } ${isOpen ? "scale-100" : "scale-0"}`}
        style={{ top: "calc(100% + 5px)", left: isOpen ? "0" : "100%" }}
      >
        {children}
      </div>
    </div>
  );
};

export default Dropdown;
